
html{
  height: 100%;
  margin: 0;
  padding: 0;
}

body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
