.App {
  height: 100%;
  margin: 0;
  font-family: "Poppins", Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

.header {
  padding: 10px 40px;
  background: #ffffff;
}
.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logo {
  font-size: 60px;
  color: #000;
  width: 88%;
}
.pageInfo{
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction : column; 
  justify-content : center; 
  align-items : center;
}